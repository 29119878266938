import React, { useCallback, useEffect, useState } from "react";
import FormatDate from "../../components/UI/FormatDate";
import PaymentForm from "../ApplicationPayment/PaymentForm";
import PromoCode from "../ApplicationPayment/PromoCode";
import PaymentService from "../../Services/PaymentService";
import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import DarkTextLogo from "../../assets/logo_text_dark.png";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import PaymentDone from "../ApplicationPayment/PaymentDone";
import ProgramBanner from "./ProgramBanner";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
import WalletDataService from "../../Services/WalletService";
import DashboardService from "../../Services/DashboardService";
import PaymentProcessing from "../ApplicationPayment/PaymentProcessing";
import WalletPay from "../Wallet/WalletPay";
import ApplicationDataService from "../../Services/ApplicationService";
import ExchangeRate from "../../Services/ExchangeRate";

export default function AdmisionDetails({
  studentCurrency,
  currentApplication,
  programDetails,
  collegeDetails,
  imageURL,
  companyLogo,
  allDraftApplication,
  checkAppStatus,
  setAllDraftApplication,
}) {
  const getUser = TokenService.getUser();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationWallet, setShowConfirmationWallet] = useState(false);
  const [paymentDoneShow, setPaymentDoneShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState();

  const getRole = TokenService.getUserRole();
  const {
    SuperAdmin,
    Counsellor,
    AdmissionOfficer,
    BranchHead,
    AgencyManager,
    TeamLeader,
  } = RoleService;

  const [selectedPromoCode, setSelectedPromoCode] = useState([]);

  const collegeDetailsApplicationFee = collegeDetails.applicationFee;
  const [totalAmount, setTotalAmount] = useState(null);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  useEffect(() => {
    const transactionPercentage = 0;
    const discountedAmount = selectedPromoCode.value
      ? (collegeDetailsApplicationFee * selectedPromoCode.value) / 100
      : 0;
    const transactionAmount = collegeDetailsApplicationFee - discountedAmount;
    const calculateTransactionAmount = (
      (transactionAmount * transactionPercentage) /
      100
    ).toFixed(2);
    const totalAmount =
      collegeDetailsApplicationFee -
      discountedAmount +
      Number(calculateTransactionAmount);
    setTotalAmount(totalAmount);

    setDiscountedAmount(discountedAmount);
  }, [collegeDetails, totalAmount, discountedAmount, selectedPromoCode]);

  const isAuthorizedToPay =
    getRole === BranchHead || getRole === Counsellor || getRole === SuperAdmin;
  const hasApplicationFee = collegeDetailsApplicationFee > 0;

  const [isPaid, setIsPaid] = useState(false);
  const [invoice, setInvoice] = useState();
  const getPaymentDetails = async () => {
    var data = {
      studentApplicationId: currentApplication.id,
      totalPayableAmount: "",
      paymentStatus: "",
      currentPage: 1,
      pageSize: 100 * 100,
    };

    try {
      const response = await PaymentService.find(data);
      const hasSuccess = response.data.response.some(
        (item) => item.statusId === 1
      );
      const successfulTransactions = response.data.response.filter(
        (item) => item.statusId === 1
      );
      setInvoice(successfulTransactions[0]);
      setIsPaid(hasSuccess);
      setIsLoading(false);
    } catch (error) {
      //console.error(error);
    }
  };

  const exchangeData = ExchangeRate({
    baseCurrency: collegeDetails?.currency ?? "",
    targetCurrency: "INR",
    baseAmount: totalAmount,
  });

  useEffect(() => {
    setAllDraftApplication(null);
    checkAppStatus();
    getPaymentDetails();
  }, [currentApplication]);

  const floatValue = (value) => {
    return value?.toFixed(2);
  };

  const formatDate = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    return `${day}/${month}/${year}`;
  };

  const [isPaying, setIsPaying] = useState(false);
  const handlePayZero = async (e) => {
    e.preventDefault();

    // Check if allDraftApplication is either false or true
    if (allDraftApplication === true) {
      setShowConfirmation(true);
    } else {
      payZero();
    }
  };

  const handleConfirmLock = async () => {
    setShowConfirmation(false); // Close the custom confirmation modal
    payZero();
  };

  const payZero = async () => {
    setIsPaying(true);
    var data = {
      studentId: currentApplication.studentId,
      studentApplicationId: currentApplication.id,
      companyId: getUser.companyId,
      totalPayableAmount: 0,
      transactionId: "",
      responseText: "succeeded",
      remarks: "zero pay success",
      statusId: 0,
      applicationFee: 0,
      promoCodeAmount: 0,
      transactionChargesAmount: 0,
      currencyConversionRate: exchangeData.exchangeRate,
      walletAmount: 0,
      statusId: 1,
      mode: "N/A",
      currency: collegeDetails.currency,
    };
    try {
      const response = await PaymentService.zeroClgApplicationFee(data);

      if (response.data.success === true) {
        getPaymentDetails();
        setPaymentDoneShow(true);
        toast.success(response.data.message);
        setIsPaying(false);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.map((verr) => toast.error(verr));
        setIsPaying(false);
      } else {
        toast.error("Something Went Wrong");
        setIsPaying(false);
      }
    } catch (error) {
      toast.error("Error zero payment :", error);
      setIsPaying(false);
    }
  };

  const [currentWalletBalance, setcurrentWalletBalance] = useState({
    totalWalletAmount: 0,
  });

  const retrieveBalance = async () => {
    try {
      const response = await DashboardService.dashboardByCompanyId();
      if (response.data.success === true) {
        setcurrentWalletBalance(response.data.response);
      }
    } catch (error) {
      toast.error("Error while fetching balance");
    }
  };

  useEffect(() => {
    retrieveBalance();
  }, []);

  const handlePayWallet = async (e) => {
    e.preventDefault();

    // Check if allDraftApplication is either false or true
    if (allDraftApplication === true) {
      setShowConfirmationWallet(true);
    } else {
      payFromWallet();
    }
  };

  const handleConfirmLockWallet = async () => {
    setShowConfirmationWallet(false); // Close the custom confirmation modal
    payFromWallet();
  };

  const [paymentProcessing, setPaymentProcessing] = useState({
    paystart: false,
    paydone: false,
  });

  const payFromWallet = async () => {
    setPaymentProcessing((prevData) => ({
      ...prevData,
      paystart: true,
    }));
    setIsPaying(true);
    var dataForPayment = {
      studentId: currentApplication.studentId,
      studentApplicationId: currentApplication.id,
      totalPayableAmount: totalAmount,
      transactionId: null,
      responseText: "succeeded",
      remarks: "wallet payment",
      statusId: 0,
      applicationFee: collegeDetailsApplicationFee,
      promoCodeAmount: discountedAmount,
      transactionChargesAmount: 0,
      currencyConversionRate: exchangeData.exchangeRate,
      walletAmount: 0,
      cardNumber: "424242424242",
      expMonth: "12",
      expYear: "44",
      cvv: "123",
      promoCodeName: selectedPromoCode.name,
      firstName: getUser.firstName,
      lastName: getUser.lastName,
      companyId: getUser.companyId,
      mode: "Wallet",
      addressCity: "",
      addressCountry: "",
      billingAddress: "",
      billingState: "",
      billingZip: "",
      currency: collegeDetails.currency,
      successed: true,
      paymentStatus: "string",
    };
    var dataForWallet = {
      amount: "-" + totalAmount,
      companyId: getUser.companyId,
      type: "Debit",
      remarks: "",
      transactionId: null,
      responseText: "succeeded",
      statusId: 1,
      mode: "Wallet",
      currencyConversionRate: 0,
      currencyConversionAmount: 0,
      studentApplicationId: currentApplication.id,
    };

    try {
      const PaymentResponse = await PaymentService.create(dataForPayment);

      const WalletResponse = await WalletDataService.create(dataForWallet);

      if (
        WalletResponse.data.success === true &&
        PaymentResponse.data.success === true
      ) {
        toast.success(PaymentResponse.data.message);
        setPaymentProcessing((prevData) => ({
          ...prevData,
          paydone: true,
        }));
      } else if (
        WalletResponse.data.validationErrors ||
        (PaymentResponse.data.success === false &&
          PaymentResponse.data.validationErrors === null)
      ) {
        WalletResponse?.data?.validationErrors?.map((verr) =>
          toast.error(verr)
        );
        PaymentResponse?.data?.validationErrors?.map((verr) =>
          toast.error(verr)
        );
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (e) {
      toast.error(e);
    } finally {
      checkAppStatus();
      getPaymentDetails();
      setIsPaying(false);
    }
  };

  return (
    <>
      <PaymentProcessing
        paymentProcessing={paymentProcessing}
        setPaymentProcessing={setPaymentProcessing}
      />
      <PaymentDone
        paymentDoneShow={paymentDoneShow}
        setPaymentDoneShow={setPaymentDoneShow}
      />
      <ConfirmationModal
        show={showConfirmationWallet}
        onHide={() => setShowConfirmationWallet(false)}
        onConfirm={handleConfirmLockWallet}
        message="Upon payment, the student's details will be locked, and you will no longer be able to modify the particulars of the student."
        title="Confirm Lock and proceed with wallet payment"
      />
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmLock}
        message="Upon payment, the student's details will be locked, and you will no longer be able to modify the particulars of the student."
        title="Confirm Lock"
      />
      <div className="row g-2">
        {getRole === BranchHead ||
        getRole === Counsellor ||
        getRole === AdmissionOfficer ||
        getRole === AgencyManager ||
        getRole === TeamLeader ||
        getRole === SuperAdmin ? (
          <div className="col-12 mt-4">
            {isLoading ? (
              ""
            ) : (
              <div className="">
                <>
                  {isPaid ? (
                    <div className="bg-light-blue1 rounded-5 border p-3 mb-3 shadow-sm text1-7 row gap-0 row-gap-3">
                      <div className="row position-relative">
                        <div className="col position-relative">
                          <img
                            src={imageURL ? imageURL : DarkTextLogo}
                            alt="CollegeLogo"
                            className="img-fluid position-absolute top-50 start-50 translate-middle mt-4"
                            style={{ opacity: "0.060", maxWidth: "400px" }}
                          />
                          <div
                            className="bg-white rounded-4 p-3 border"
                            style={{ minHeight: "300px" }}
                          >
                            <div className="bg-dark1 rounded-pill text-white d-flex justify-content-between align-items-center py-2 bg-gradient px-3 col-12 text2 text-start mb-4">
                              <span className="font-bold ms-3">
                                Application Details{" "}
                              </span>
                            </div>
                            <div className="px-5">
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">
                                  Application Id:{" "}
                                </div>
                                <div>
                                  <FormatDate
                                    date={currentApplication.createdDate}
                                    idNo={currentApplication.idNumber}
                                    customCSS={false}
                                  />
                                </div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">Program Name: </div>
                                <div className="text1-4 text-end">
                                  {programDetails.name}
                                </div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">College Name: </div>
                                <div>{collegeDetails.name}</div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">Campus: </div>
                                <div>{currentApplication?.campus}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-3">
                                <div className="font-bold">Intake Status: </div>
                                <div>
                                  <FormatIntkeDate
                                    date={currentApplication.intake}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col position-relative">
                          <img
                            src={DarkTextLogo}
                            alt="DarkTextLogo"
                            className="img-fluid position-absolute top-50 start-50 translate-middle"
                            style={{ opacity: "0.060", maxWidth: "400px" }}
                          />
                          <div
                            className="bg-white rounded-4 p-3 border"
                            style={{ minHeight: "300px" }}
                          >
                            <div className="bg-dark1 rounded-pill text-white d-flex justify-content-between align-items-center py-2 bg-gradient px-3 col-12 text2 text-start mb-4">
                              <span className="font-bold ms-3">
                                Transaction Details{" "}
                              </span>
                            </div>
                            <div className="px-5">
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">
                                  Transaction Id:{" "}
                                </div>
                                <div className="text1-5">
                                  {invoice.transactionId === null ||
                                  invoice.transactionId === ""
                                    ? invoice.id
                                    : invoice.transactionId}
                                </div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">Date: </div>
                                <div>{formatDate(invoice.createdDate)}</div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">
                                  Application Fee:{" "}
                                </div>
                                <div>
                                  {collegeDetails.currency}{" "}
                                  {floatValue(invoice.applicationFee)}
                                </div>
                              </div>
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">
                                  Discount Amount:{" "}
                                </div>
                                <div>
                                  {collegeDetails.currency}{" "}
                                  {floatValue(invoice.promoCodeAmount)}
                                </div>
                              </div>
                              {/* <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">Transaction Charges Amount: </div>
                                <div>${floatValue(invoice.transactionChargesAmount)}</div>
                              </div> */}
                              <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                                <div className="font-bold">
                                  Total Paid Amount:{" "}
                                </div>
                                <div>
                                  {collegeDetails.currency}{" "}
                                  {floatValue(invoice.totalPayableAmount)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="font-bold">
                                  Total Paid Amount {`(in INR)`}:
                                </div>
                                <div>
                                  INR {exchangeData.convertedAmount ?? "0.00"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-12 mt-4">
                        <div className="">
                          <ProgramBanner
                            currentApplication={currentApplication}
                            imageURL={imageURL}
                            companyLogo={companyLogo}
                            programDetails={programDetails}
                            collegeDetails={collegeDetails}
                          />
                        </div>
                      </div>
                      <div className="bg-light-blue rounded-5 p-3 border mb-3 shadow-sm text1-8 row gap-0 row-gap-3 text-end mt-4">
                        <div className="col text2 text-start">
                          <span className="font-bold">Payment Details </span>
                        </div>
                        <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">Application fee: </div>
                          <div>
                            {collegeDetails.currency}{" "}
                            {floatValue(collegeDetailsApplicationFee)}
                          </div>
                        </div>
                        <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">
                            Discount:{" "}
                            {collegeDetailsApplicationFee === 0 ? (
                              "No Promo code available"
                            ) : (
                              <PromoCode
                                currentApplication={currentApplication}
                                setSelectedPromoCode={setSelectedPromoCode}
                              />
                            )}
                          </div>
                          <div>
                            {collegeDetails.currency}{" "}
                            {floatValue(discountedAmount)} @
                            {selectedPromoCode.value}%
                          </div>
                        </div>
                        {/* <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">Transaction Charges @{transactionPercentage}%</div>
                          <div>${calculateTransactionAmount}</div>
                        </div> */}
                        <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">Total payable fee: </div>
                          <div>
                            {collegeDetails.currency} {floatValue(totalAmount)}
                          </div>
                        </div>
                        <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">
                            Total payable fee {`(in INR)`}:{" "}
                          </div>
                          <div>
                            INR {exchangeData.convertedAmount ?? "0.00"}
                          </div>
                        </div>
                        <div className="dashed-border-bottom-horizontal d-flex justify-content-between mb-3">
                          <div className="font-bold">
                            Current Wallet Balance:
                          </div>
                          <div>
                            {collegeDetails.currency}{" "}
                            {floatValue(currentWalletBalance.totalWalletAmount)}
                          </div>
                        </div>
                        <div className="mb-3">
                          {hasApplicationFee && isAuthorizedToPay ? (
                            <div className="d-flex justify-content-end align-items-center">
                              {!isPaying && (
                                <>
                                  <PaymentForm
                                    studentCurrency={studentCurrency}
                                    collegeCurrency={collegeDetails?.currency}
                                    exchangeData={exchangeData}
                                    currentApplication={currentApplication}
                                    totalAmount={totalAmount}
                                    PaymentService={PaymentService}
                                    getPaymentDetails={getPaymentDetails}
                                    selectedPromoCode={selectedPromoCode}
                                    allDraftApplication={allDraftApplication}
                                    collegeDetailsApplicationFee={
                                      collegeDetailsApplicationFee
                                    }
                                    setPaymentDoneShow={setPaymentDoneShow}
                                  />
                                </>
                              )}
                              {!isPaying && <span className="mx-3">OR</span>}
                              {currentWalletBalance.totalWalletAmount >=
                              totalAmount ? (
                                <button
                                  className="btn btn-light-blue-outline text1-8 rounded-pill py-3 px-5"
                                  onClick={handlePayWallet}
                                  disabled={isPaying}
                                >
                                  <span>
                                    {isPaying
                                      ? "Please Wait..."
                                      : "Pay from Wallet"}
                                  </span>
                                  {isPaying && (
                                    <span className="spinner-border"></span>
                                  )}
                                </button>
                              ) : (
                                <WalletPay
                                  findWalletData={getPaymentDetails}
                                  retrieveDashbaord={retrieveBalance}
                                />
                              )}
                            </div>
                          ) : isAuthorizedToPay && !hasApplicationFee ? (
                            <button
                              className="btn btn-light-blue-outline text1-8 rounded-pill py-3 px-5"
                              onClick={handlePayZero}
                              disabled={isPaying}
                            >
                              <span>
                                {isPaying ? "Please Wait..." : "Pay to Submit"}
                              </span>
                              {isPaying && (
                                <span className="spinner-border"></span>
                              )}
                            </button>
                          ) : (
                            <button
                              className="btn text1-8 rounded-pill py-3 px-5"
                              disabled
                            >
                              You are not authorized to Pay
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
